<template>
    <div class="tooltip"><slot></slot>
        <section class="tooltiptext box has-background-white-bis">
            <div class="is-size-4 has-background-grey-lighter">
                <slot name="title"></slot>
            </div>
            <div class="is-family-primary has-text-weight-normal">
                <slot name="content"></slot>
            </div>           
        </section>
    </div>
</template>

<script>
export default {
    props: {
        tipHeading: String,
        tipContent: String,
        tipType: String,
    },

    data() {
        return {
            closable: false,
        }
    }
}
</script>

<style>
.tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  /* background-color: none; */
  border: 2px;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%;  /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

</style>