<template>
    <nav class="navbar is-fixed-bottom is-light nabar-center">     
        <div class="navbar-item navbar-center"> 
            <span class="content is-centered">
                <strong>&copy; Copyright</strong> <a href="https://veecli.com"  target="twin"><strong>VEECLi</strong></a><strong>.  All rights reserved.</strong> <slot></slot>
                <!-- <idle-logout></idle-logout> -->
                {{  }}
            </span>
        </div>
    </nav>

</template>

<script>


export default {
    components: {
        // IdleLogout
    },

    data() {
        return {

        }
    },

    methods: {

        beforeWindowUnload() {
            alert("Unloading")
        },

        mounted() {
            window.addEventListener('beforeunload', this.beforeWindowUnload)
        }

    }

}
</script>

<style>

.navbar-item.navbar-center {
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
}

.navbar-center a {
	color: black;
}
</style>